var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preferred-treatment" },
    [
      _c("h2", { staticClass: "question-title" }, [
        _vm._v("What treatment would you prefer?"),
      ]),
      _c(
        "div",
        { staticClass: "group-mb", attrs: { role: "group" } },
        [
          _c("v-select", {
            staticClass: "custom-input-style",
            attrs: {
              options: _vm.options,
              label: "text",
              reduce: (options) => options.value,
              "append-to-body": "",
              searchable: false,
              "calculate-position": _vm.withPopper,
              placeholder: "Select treatment type",
            },
            scopedSlots: _vm._u([
              {
                key: "open-indicator",
                fn: function ({ attributes }) {
                  return [
                    _c(
                      "b-icon",
                      _vm._b(
                        { attrs: { icon: "chevron-down" } },
                        "b-icon",
                        attributes,
                        false
                      )
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedTreatment,
              callback: function ($$v) {
                _vm.selectedTreatment = $$v
              },
              expression: "selectedTreatment",
            },
          }),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "12", md: "9" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "info",
                    block: "",
                    pill: "",
                    disabled: _vm.saving,
                  },
                  on: { click: _vm.submitAnswer },
                },
                [_vm._v(_vm._s(_vm.saving ? "Saving" : "Continue"))]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "9" } },
            [
              !_vm.saving
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-info",
                        block: "",
                        pill: "",
                        disabled: _vm.saving,
                      },
                      on: { click: _vm.submitDoctorChoice },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.saving ? "Saving" : "I want doctor to recommend"
                        )
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }