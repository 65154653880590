var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.productRevealed
    ? _c(
        "div",
        { staticClass: "product-reveal" },
        [
          _vm.onboardingData.couponid
            ? _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "bg-primary px-4 py-3 text-secondary",
                          attrs: { "no-body": "" },
                        },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "b-row",
                                { attrs: { "align-h": "between" } },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-center text-sm-left",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _vm._v(" Active Discount: "),
                                      _c("br"),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "heading text-secondary",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.onboardingData.couponid)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-center text-sm-right",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _vm.couponData.percent_off
                                        ? _c("span", [
                                            _vm._v(
                                              "Enjoy " +
                                                _vm._s(
                                                  _vm.couponData.percent_off
                                                ) +
                                                "% off "
                                            ),
                                            _vm.couponData.applies_to
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.discountedProduct
                                                          .name
                                                      ) +
                                                      " for "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.couponData.duration == "once"
                                              ? _c("span", [
                                                  _vm._v(
                                                    " your first treatment plan!"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _c("br"),
                                      _c(
                                        "span",
                                        { staticClass: "text-small" },
                                        [_vm._v("Discount applied at checkout")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.body.length > 0
            ? _c("div", [
                _c("h2", { staticClass: "question-title" }, [
                  _vm._v(
                    " Based on your symptoms and preference, Winona Physician suggests... "
                  ),
                ]),
                _c("p", { staticClass: "sub-text" }, [
                  _vm._v(
                    " Choose a plan. Don't worry - you can tweak this later, and your doctor will review if this treatment is right for you. "
                  ),
                ]),
              ])
            : _c("div", { staticClass: "mt-5" }, [
                _c("h2", { staticClass: "question-title" }, [
                  _vm._v(
                    " Based on your medical questionnaire, we currently cannot suggest any treatment. "
                  ),
                ]),
                _c("p", { staticClass: "sub-text" }, [
                  _vm._v(
                    " We recommend checking out some of our non prescription wellness products. "
                  ),
                ]),
              ]),
          _c(
            "b-modal",
            {
              attrs: {
                "hide-header": "",
                "hide-footer": "",
                centered: "",
                "content-class": "dark-bg",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                "no-stacking": "",
              },
              model: {
                value: _vm.progesteroneModal,
                callback: function ($$v) {
                  _vm.progesteroneModal = $$v
                },
                expression: "progesteroneModal",
              },
            },
            [
              _c(
                "b-row",
                { staticClass: "mb-6" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "icon-logo dark" }),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "modal-card" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "sub-text",
                      staticStyle: { color: "inherit" },
                    },
                    [
                      _vm._v(
                        " Because of your medical history, it would not be safe to take Estrogen without Progesterone. "
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "info", pill: "", block: "" },
                      on: {
                        click: function ($event) {
                          _vm.progesteroneModal = false
                        },
                      },
                    },
                    [_vm._v("Continue")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _vm.body.length > 0
                ? _c(
                    "b-col",
                    _vm._l(_vm.body, function (product) {
                      return _c("ProductRevealCheckbox", {
                        key: product.id,
                        ref: "ProductRevealCheckbox",
                        refInFor: true,
                        attrs: {
                          product: product,
                          existingPricing: _vm.getPricingFromOnboarding(
                            product.id
                          ),
                        },
                        on: {
                          "set-selection": _vm.updateSelection,
                          "update-shipping": _vm.updateShipping,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", lg: "12" } },
                [
                  _vm.body.length > 0
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "mb-3",
                              attrs: { cols: "12", lg: "12" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "info",
                                    pill: "",
                                    block: "",
                                    disabled: _vm.buttonDisabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitAnswer(true)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.buttonText))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "mb-3",
                              attrs: { cols: "12", lg: "12" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "info",
                                    pill: "",
                                    block: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("exit")
                                    },
                                  },
                                },
                                [_vm._v("Continue")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "text-center" }, [
            _c(
              "a",
              {
                staticClass:
                  "text-default text-underline d-inline-block mt-4 py-3 mx-auto",
                attrs: { href: "javascript:;" },
                on: { click: _vm.skipPreference },
              },
              [_vm._v("Skip Preference, I'd like to discuss with the doctor")]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }