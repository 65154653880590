<template>
  <div class="product-reveal" v-if="productRevealed">
    <b-row v-if="onboardingData.couponid" class="mb-4">
      <b-col>
        <b-card class="bg-primary px-4 py-3 text-secondary" no-body>
          <span>
            <b-row align-h="between">
              <!-- WIN-8178 -->
              <b-col cols="12" sm="6" class="text-center text-sm-left">
                <!-- TODO Apply the component here -->
                Active Discount: <br />
                <span class="heading text-secondary">{{
                  onboardingData.couponid
                }}</span>
              </b-col>
              <b-col cols="12" sm="6" class="text-center text-sm-right">
                <span v-if="couponData.percent_off"
                  >Enjoy {{ couponData.percent_off }}% off
                  <span v-if="couponData.applies_to">
                    {{ discountedProduct.name }} for
                  </span>
                  <span v-if="couponData.duration == 'once'">
                    your first treatment plan!</span
                  ></span
                >
                <br />
                <span class="text-small">Discount applied at checkout</span>
              </b-col>
            </b-row>
          </span>
        </b-card>
      </b-col>
    </b-row>
    <div v-if="body.length > 0">
      <h2 class="question-title">
        Based on your symptoms and preference, Winona Physician suggests...
      </h2>
      <p class="sub-text">
        Choose a plan. Don't worry - you can tweak this later, and your doctor
        will review if this treatment is right for you.
      </p>
    </div>
    <div v-else class="mt-5">
      <h2 class="question-title">
        Based on your medical questionnaire, we currently cannot suggest any
        treatment.
      </h2>
      <p class="sub-text">
        We recommend checking out some of our non prescription wellness
        products.
      </p>
    </div>

    <!-- Progesterone Modal -->
    <b-modal
      v-model="progesteroneModal"
      hide-header
      hide-footer
      centered
      content-class="dark-bg"
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo dark"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <p class="sub-text" style="color: inherit">
          Because of your medical history, it would not be safe to take Estrogen
          without Progesterone.
        </p>
        <b-button variant="info" pill block @click="progesteroneModal = false"
          >Continue</b-button
        >
      </div>
    </b-modal>

    <b-row class="mt-2">
      <b-col v-if="body.length > 0">
        <ProductRevealCheckbox
          ref="ProductRevealCheckbox"
          v-for="product in body"
          :key="product.id"
          :product="product"
          @set-selection="updateSelection"
          @update-shipping="updateShipping"
          :existingPricing="getPricingFromOnboarding(product.id)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="12">
        <b-row v-if="body.length > 0">
          <b-col cols="12" lg="12" class="mb-3">
            <b-button
              variant="info"
              pill
              block
              @click="submitAnswer(true)"
              :disabled="buttonDisabled"
              >{{ buttonText }}</b-button
            >
          </b-col>
        </b-row>
        <!-- <b-row v-else>
          <b-col cols="12" lg="6" class="mb-3">
            <b-button
              variant="outline-info"
              pill
              block
              @click="redirect()"
              :disabled="buttonDisabled"
              >No thanks</b-button
            >
          </b-col>
          <b-col cols="12" lg="6" class="mb-3">
            <b-button
              variant="info"
              pill
              block
              @click="submitAnswer(true)"
              :disabled="buttonDisabled"
              >Continue</b-button
            >
          </b-col>
        </b-row> -->
        <b-row v-else>
          <b-col cols="12" lg="12" class="mb-3">
            <b-button variant="info" pill block @click="$emit('exit')"
              >Continue</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="text-center">
      <a
        class="text-default text-underline d-inline-block mt-4 py-3 mx-auto"
        href="javascript:;"
        @click="skipPreference"
        >Skip Preference, I'd like to discuss with the doctor</a
      >
    </div>
  </div>
</template>

<script>
import ProductRevealCheckbox from "@/components/onboarding/treatments/ProductRevealCheckbox";
import { OnboardingService } from "@/services/onboarding.service";
import { ProductService } from "@/services/product.service";

export default {
  components: {
    ProductRevealCheckbox,
  },
  props: {
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    body: {
      type: Array,
    },
    userId: {
      type: String,
      default: "",
    },
    productRevealed: {
      type: Boolean,
      default: false,
    },
    onboardingflow: {
      type: String,
      default: "flowA",
    },
    analyticsId: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "",
    },
    phoneno: {
      type: String,
      default: "",
    },
    firstname: {
      type: String,
      default: "",
    },
    lastname: {
      type: String,
      default: "",
    },
    onboardingData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    async onboardingData(data) {
      if (data.couponid) {
        const coupondata = await OnboardingService.checkCoupon(data.couponid);
        this.couponData = coupondata.data;
      }
    },
    async couponData(coupon) {
      if (coupon.applies_to) {
        const { data } = await ProductService.getProduct(
          coupon.applies_to.products[0]
        );
        this.discountedProduct = data;
      }
    },
  },
  computed: {
    buttonText() {
      return this.buttonDisabled ? "Submitting" : "Continue";
    },
  },
  data() {
    return {
      progesteroneModal: false,
      desiredplan: [],
      couponData: {},
      discountedProduct: {
        name: "",
      },
    };
  },
  methods: {
    getPricingFromOnboarding(productid) {
      if (this.onboardingData.hasOwnProperty("desiredplan")) {
        const desiredplanobj = this.onboardingData.desiredplan.find(
          (o) => o.productid == productid
        );
        if (desiredplanobj) {
          return desiredplanobj.pricingdetail.pricingid;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    updateSelection(id, selected, pricing, productselect, productdeselect) {
      // Manual selection and deselection
      if (selected) {
        // Item is not yet in the array
        if (!this.desiredplan.some((obj) => obj.productid == id)) {
          this.desiredplan.push({
            productid: id,
            pricingid: pricing,
          });
        } else {
          // Item is already in the array (Update pricing)
          this.desiredplan.forEach((o) => {
            if (o.productid == id && o.pricingid != pricing) {
              o.pricingid = pricing;
            }
          });
        }
      } else {
        this.desiredplan = this.desiredplan.filter(
          (obj) => obj.productid != id
        );
      }
      for (let currentProduct of this.$refs.ProductRevealCheckbox) {
        if (productselect != undefined) {
          if (productselect.includes(currentProduct.product.id) && selected) {
            currentProduct.setSelected(true);
          }
        }

        if (productdeselect != undefined) {
          if (
            productdeselect.includes(currentProduct.product.id) &&
            !selected
          ) {
            if (currentProduct.selected) {
              this.progesteroneModal = true;
            }
            currentProduct.setSelected(false);
          }
        }
      }
    },
    updateShipping(
      id,
      pricing,
      productselect,
      productdeselect,
      availablepricing
    ) {
      /**
       * 1. Update shipping id to whatever is supplied
       * 2. Check productselect for any matching items
       * 3. If there is a match, make sure that the subscriptiontype is the same
       */

      this.desiredplan.forEach((o) => {
        if (o.productid == id && o.pricingid != pricing) {
          o.pricingid = pricing;
        }
      });

      let currentSubscriptionType = availablepricing.find((o) => {
        return o.pricingid == pricing;
      }).subscriptiontype;

      if (productselect != undefined || productdeselect != undefined) {
        for (let currentProduct of this.$refs.ProductRevealCheckbox) {
          // if match is found
          if (
            (productselect != undefined &&
              productselect.includes(currentProduct.product.id)) ||
            (productdeselect != undefined &&
              productdeselect.includes(currentProduct.product.id))
          ) {
            // If match is selected
            if (currentProduct.selected) {
              // Check selectedpricing
              let targetPricings = currentProduct.product.availablepricing;

              // Set target pricing to correct subscription type
              currentProduct.selectedpricing = targetPricings.find((o) => {
                return o.subscriptiontype == currentSubscriptionType;
              }).pricingid;
            }
          }
        }
      }
    },
    submitAnswer(answer) {
      let answers = [
        {
          questionid: "agreedToMedication",
          answer: answer,
        },
        {
          questionid: "selectedProduct",
          answer: this.desiredplan,
        },
      ];
      this.$emit("submitAnswer", answers);
    },
    async fbqTrack(data) {
      // FB Pixel Tracking
      try {
        fbq("init", "348219289912097", {
          em: this.email,
          fn: this.firstname,
          ln: this.lastname,
          ph: this.phoneno,
          external_id: this.userid,
          extern_id: this.userid,
        });
        // fbq("track", "PageView");
        let pageViewBody = { userid: this.userid };
        OnboardingService.onboardingEvent("PageView", pageViewBody);
        fbq(
          "trackCustom",
          "TreatmentOptionSkipped",
          {
            external_id: this.userid,
            extern_id: this.userid,
            treatment: this.selectedproduct,
            ...(await this.$store.dispatch("getFBQParams")),
          },
          {
            eventID: data.eventid,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async skipPreference() {
      const body = {
        agreedtomedication: false,
        letdoctorchoose: true,
      };
      const data = await OnboardingService.updatePatientOnboarding(3.2, body);

      const res = await OnboardingService.updatePatientOnboarding(4, {
        desiredaddon: [],
      });

      // Analytics
      window.analytics.track(
        "Treatment Option Skipped",
        {
          firstname: this.firstname,
          lastname: this.lastname,
          customerId: this.analyticsId,
          onboardingflow: this.onboardingflow,
          phoneno: this.phoneno,
          state: this.state,
          email: this.email,
          messageID: data.data.eventid,
          ...(await this.$store.dispatch("getDeviceDetailsForContext")),
          ...(await this.$store.dispatch("getVoluumDetails")),
        },
        {
          referrer: await this.$store.dispatch("getEverflowDetails"),
          context: await this.$store.dispatch("geDeviceDetails"),
        }
      );

      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({
      //   event: "Treatment Option Skipped",
      //   customerID: this.userId,
      // });

      if (data.data.eventid) {
        await this.fbqTrack(data.data);
      } else if (
        data.data.eventid &&
        process.env.VUE_APP_SERVER != "production"
      ) {
        //alert('FB TRACKING TreatmentChosen: '+ data.data.eventid);
        console.log("FB TRACKING TreatmentSkipped");
        console.log("FB TRACKING TreatmentSkipped: " + data.data.eventid);
      }

      if (data) {
        // Multiflow Routing
        if (this.onboardingflow == "flowB") {
          this.$router.push("/checkout").catch(() => {});
        } else {
          this.$router.push("/identity-verification").catch(() => {});
        }
      }
    },
  },
};
</script>

<style>
.text-small {
  font-size: 0.8rem !important;
}
</style>
