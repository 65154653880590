<template>
  <b-container fluid>
    <!-- for Desktop Only Progress -->
    <OnboardingProgress @back="prev" :progress="progress" />
    <b-row class="onboarding-wrapper">
      <OnboardingHeader @back="prev" step="3" :title="title" />
      <b-col
        cols="12"
        lg="5"
        xl="5"
        xxl="4"
        class="mt-6 pt-3 onboarding-content"
      >
        <b-alert :show="alert.show" :variant="alert.type">{{
          alert.message
        }}</b-alert>
        <!-- <h6 class="d-lg-none mt-3 mb-2 step">Step 3 of 4</h6> -->

        <!-- mobile progress -->
        <b-row class="d-lg-none">
          <b-col>
            <h3 class="bold d-letter-spacing onboarding-title">
              Onboarding Completion
            </h3>
          </b-col>
          <b-col>
            <h3
              class="
                bold
                d-letter-spacing
                onboarding-title
                text-muted text-right
              "
            >
              <!-- Onboarding Complete -->
            </h3>
          </b-col>
        </b-row>
        <b-progress
          :value="60"
          :max="100"
          class="step-progress d-lg-none mb-5"
          variant="purple"
          height="5px"
        ></b-progress>

        <ProductReveal
          v-if="step == 'productreveal'"
          @submitAnswer="submitAnswer"
          :body="revealedProducts"
          ref="productReveal"
          :productRevealed="productRevealed"
          :selectedproduct="desiredplan"
          :user-id="userid"
          :analytics-id="analyticsId"
          :buttonDisabled="buttonDisabled"
          :email="email"
          :state="state"
          :phoneno="phoneno"
          :firstname="firstname"
          :lastname="lastname"
          @exit="exit"
          :onboardingflow="onboardingflow"
          :onboarding-data="onboardingData"
        />
        <Single
          class="shipping-price"
          v-if="step == 'preferredshipment'"
          :body="shippinginfo"
          @submitAnswer="submitAnswer"
        />
        <PreferredTreatment
          ref="PreferredTreatment"
          :saving="treatmentformsaving"
          v-if="step == 'preferredtreatment'"
          @submitAnswer="submitAnswer"
          @alert="showAlert"
        />
      </b-col>
      <b-col cols="12" lg="3" xl="3" xxl="4"></b-col>
    </b-row>
  </b-container>
</template>

<script>
import ProductReveal from "@/components/onboarding/treatments/ProductReveal";
import Single from "@/components/onboarding/questions/Single";
import PreferredTreatment from "@/components/onboarding/treatments/PreferredTreatment";
import { OnboardingService } from "@/services/onboarding.service";
import { LookUpService } from "@/services/lookup.service";
import { generateEventId } from "@/services/util";
import { ProductService } from "@/services/product.service";
import OnboardingHeader from "@/components/partials/OnboardingHeader";
import OnboardingProgress from "@/components/partials/OnboardingProgress";
// Flow B
import Complete from "@/components/onboarding/checkout/Complete";

export default {
  props: ["title"],
  components: {
    ProductReveal,
    Single,
    PreferredTreatment,
    OnboardingHeader,
    OnboardingProgress,
  },
  data() {
    return {
      userid: null,
      analyticsId: null,
      treatmentformsaving: false,
      currentonboardingstep: null,
      alert: {
        show: false,
        type: "",
        message: "",
      },
      onboardingData: {},
      step: "productreveal",
      revealedProducts: [],
      shippinginfo: {
        question: "Choose your shipping & billing frequency",
        header:
          "Bundle your treatments to save money — this can be changed at any time.",
        id: "preferredShipment",
        answerlist: [],
      },
      treatmentinfo: {
        question: "What treatment would you prefer?",
        id: "preferredTreatment",
      },
      agreedtomedication: null,
      letdoctorchoose: null,
      preferredtreatment: null,
      preferredshipment: null,
      desiredplan: [],
      productRevealed: false,
      exiting: false,

      email: "",
      firstname: "",
      lastname: "",
      phoneno: "",
      state: "",
      buttonDisabled: false,
      onboardingflow: "flowA",

      // for new progress UI update on the header
      progress: [
        {
          title: "Account Creation",
          value: 100,
          max: 100,
        },
        {
          title: "Intake",
          value: 5,
          max: 5,
        },
        {
          title: "Onboarding Complete",
          value: 1,
          max: 5,
        },
      ],
    };
  },
  methods: {
    // Answer handling and redirection
    async submitAnswer(params, answer = null) {
      this.alert.show = false;
      this.buttonDisabled = true;
      switch (this.step) {
        case "productreveal":
          this.agreedtomedication = params[0].answer;
          this.desiredplan = params[1].answer;

          if (
            this.agreedtomedication === true &&
            this.desiredplan.length === 0 &&
            this.revealedProducts.length > 0
          ) {
            this.showAlert(
              "Please select at least one from our product recommendation."
            );
            this.buttonDisabled = false;
            break;
          }

          if (params[0].answer) {
            if (typeof FS !== "undefined") {
              FS.event("Treatment Option Chosen");
            }
          }
          // Redirects to specific steps
          window.analytics.track(
            "Onboarding Screen Viewed",
            {
              customerId: this.analyticsId,
              screen: "3.3-A",
              onboardingflow: this.onboardingflow,
              firstname: this.firstname,
              lastname: this.lastname,
              ...(await this.$store.dispatch("getDeviceDetailsForContext")),
              ...(await this.$store.dispatch("getVoluumDetails")),
            },
            {
              referrer: await this.$store.dispatch("getEverflowDetails"),
              context: await this.$store.dispatch("geDeviceDetails"),
            }
          );
          //**************************************** JAMES UPDATE ****************************************//
          if (
            this.agreedtomedication === true &&
            this.revealedProducts.length > 0
          ) {
            //****** CUSTOM PARSING OF TEXT DESCRIPTION ON SHIPMENT DEPENDING ON THE SELECTED PRODUCT ******//
            for (let shipment of this.shippinginfo.answerlist) {
              let totalamount = 0;
              for (let revealprod of this.revealedProducts.filter((x) =>
                this.desiredplan.includes(x.id)
              )) {
                for (let price of revealprod.availablepricing.filter(
                  (x) => x.subscriptiontype === shipment.subscriptiontype
                )) {
                  totalamount += price.price;
                }

                // IF THERE IS NO MONTHLY PRICING AVAILABLE, USE THE QUARTERLY THEN DIVIDE IT BY 3
                if (
                  revealprod.availablepricing.filter(
                    (x) => x.subscriptiontype === "Monthly"
                  ).length === 0 &&
                  shipment.subscriptiontype === "Monthly"
                ) {
                  let quarterlyprice = revealprod.availablepricing.find(
                    (x) => x.subscriptiontype === "Quarterly"
                  );
                  if (quarterlyprice != undefined) {
                    totalamount += quarterlyprice.price / 3;
                  }
                }

                // IF THERE IS NO QUARTERLY PRICING AVAILABLE, USE THE MONTHLY THEN MULTIPLY IT BY 3
                if (
                  revealprod.availablepricing.filter(
                    (x) => x.subscriptiontype === "Quarterly"
                  ).length === 0 &&
                  shipment.subscriptiontype === "Quarterly"
                ) {
                  let monthlyprice = revealprod.availablepricing.find(
                    (x) => x.subscriptiontype === "Monthly"
                  );
                  if (monthlyprice != undefined) {
                    totalamount += monthlyprice.price * 3;
                  }
                }
              }

              if (shipment.subscriptiontype === "Quarterly") {
                shipment.description = `Receive a 3 month supply for $${totalamount.toFixed(
                  2
                )} every 3 months, cancel anytime.`;
                shipment.discountedprice = `$${(totalamount / 3).toFixed(
                  2
                )}/mo`;
                shipment.totalpricepermonth = Number(
                  (totalamount / 3).toFixed(2)
                );
              } else if (shipment.subscriptiontype === "Monthly") {
                shipment.description =
                  "Receive your treatment every month, cancel anytime. ";
                shipment.regularprice = `$${totalamount.toFixed(2)}/mo`;
                shipment.totalprice = Number(totalamount.toFixed(2));
              }
            }

            //FINALIZE
            let monthyshipment = this.shippinginfo.answerlist.find(
              (x) => x.subscriptiontype === "Monthly"
            );
            for (let shipment of this.shippinginfo.answerlist.filter(
              (x) => x.subscriptiontype != "Monthly"
            )) {
              if (monthyshipment.totalprice != shipment.totalpricepermonth) {
                shipment.regularprice = `$${monthyshipment.totalprice}`;
              } else {
                delete shipment.discountedprice;
                shipment.regularprice = `$${shipment.totalpricepermonth}/mo`;
              }
            }
          } else {
            for (let shipment of this.shippinginfo.answerlist) {
              shipment.regularprice = "";
              shipment.description = "";
              // shipment.discountedprice = "";
              delete shipment.discountedprice;
            }
          }
          //**********************************************************************************************//

          if (params[0].answer) {
            this.buttonDisabled = false;
            this.submitToOnboarding();
          } else {
            this.buttonDisabled = false;
            this.step = "preferredtreatment";
          }
          break;
        case "letDoctorChoose":
          this.letdoctorchoose = params.answer;
          break;
        case "preferredtreatment":
          if (params.doctorPref) {
            this.letdoctorchoose = true;
            this.step = "preferredshipment";
          } else {
            this.treatmentformsaving = true;
            // this.preferredtreatment = answer;

            let previousreveal = this.revealedProducts;
            const data = await OnboardingService.updateTreatmentForm({
              treatmentform: answer,
            });

            const reveal = await OnboardingService.getProductReveal();

            let newproductsfromreveal = [];
            for (let revrecord of reveal.data) {
              if (
                previousreveal.filter((x) => x.id === revrecord.id).length === 0
              ) {
                newproductsfromreveal.push(revrecord.id);
              }
            }

            this.revealedProducts = reveal.data;
            this.desiredplan = this.desiredplan.concat(newproductsfromreveal);

            this.letdoctorchoose = false;
            this.treatmentformsaving = false;
            this.buttonDisabled = false;
            this.step = "productreveal";
          }

          break;
        case "preferredshipment":
          this.preferredshipment = answer;
          this.submitToOnboarding();
          break;
      }
    },
    async fbqTrack(data) {
      try {
        // FB Pixel Tracking
        fbq("init", "348219289912097", {
          em: this.email,
          fn: this.firstname,
          ln: this.lastname,
          ph: this.phoneno,
          external_id: this.userid,
          extern_id: this.userid,
        });
        // fbq("track", "PageView");
        // await OnboardingService.onboardingEvent("");
        fbq(
          "trackCustom",
          "TreatmentChosen",
          {
            external_id: this.userid,
            extern_id: this.userid,
            treatment: this.selectedproduct,
            state: this.state,
            ...(await this.$store.dispatch("getFBQParams")),
          },
          {
            eventID: data.eventid,
          }
        );
      } catch (err) {
        // console.log(err);
      }
    },
    // * Separate method to prepare params
    async submitToOnboarding() {
      this.buttonDisabled = true;
      // Build answer based on conditions
      let params;
      if (this.agreedtomedication) {
        params = {
          agreedtomedication: this.agreedtomedication,
        };
      } else if (this.letdoctorchoose) {
        params = {
          agreedtomedication: this.agreedtomedication,
          letdoctorchoose: this.letdoctorchoose,
          preferredshipment: this.preferredshipment,
        };
      } else {
        params = {
          agreedtomedication: this.agreedtomedication,
          letdoctorchoose: this.letdoctorchoose,
          desiredplan: this.desiredplan,
        };
      }

      if (this.desiredplan.length > 0 && !this.letdoctorchoose) {
        params.desiredplan = this.desiredplan;
      }

      this.$emit("setOnboardingStep", 3.2);
      try {
        const data = await OnboardingService.updatePatientOnboarding(
          3.2,
          params
        );

        let { data: nonrxProducts } = await ProductService.listProductNonRX();
        let nonrxIds = nonrxProducts.map((product) => product.id);

        let selectedAddons = [];
        for (let product of this.desiredplan) {
          console.log(product);

          if (nonrxIds.indexOf(product) > -1) selectedAddons.push(product);
        }

        let addonsToSubmit = [];
        for (let addon of selectedAddons) {
          console.log(addon);
          addonsToSubmit.push({ productid: addon });
        }
        console.log("addonsToSubmit", addonsToSubmit);
        const res = await OnboardingService.updatePatientOnboarding(4, {
          desiredaddon: addonsToSubmit,
        });

        if (this.currentonboardingstep === 3.2) {
          window.analytics.track(
            "Treatment Option Chosen",
            {
              treatment: JSON.parse(JSON.stringify(this.desiredplan)),
              customerId: this.analyticsId,
              onboardingflow: this.onboardingflow,
              phoneno: this.phoneno,
              state: this.state,
              email: this.email,
              messageID: data.data.eventid,
              firstname: this.firstname,
              lastname: this.lastname,
              ...(await this.$store.dispatch("getDeviceDetailsForContext")),
              ...(await this.$store.dispatch("getVoluumDetails")),
              // ...(await this.$store.dispatch("getFBQParams")),
            },
            {
              referrer: await this.$store.dispatch("getEverflowDetails"),
              context: await this.$store.dispatch("geDeviceDetails"),
            }
          );

          // WIN-14971
          // WIN-20853 - Remove Northbeam from Onboarding
          // window.Northbeam.fireCustomGoal("Treatment Chosen", {});
          // console.log("Northbeam: Treatment Chosen");

          // GTM: Mariwin
          // window.dataLayer = window.dataLayer || [];
          // window.dataLayer.push({
          //   event: "Treatment Option Chosen",
          //   customerID: this.userid,
          //   treatment: this.desiredplan,
          // });

          if (data.data.eventid) {
            await this.fbqTrack(data.data);
          } else if (
            data.data.eventid &&
            process.env.VUE_APP_SERVER != "production"
          ) {
            //alert('FB TRACKING TreatmentChosen: '+ data.data.eventid);
            console.log("FB TRACKING TreatmentChosen");
            console.log("FB TRACKING TreatmentChosen: " + data.data.eventid);
          }
        }

        this.$router.push("/identity-verification").catch(() => {});
      } catch (err) {
        if (err.status == 401) {
          this.exiting = true;
          this.$router.push("/login");
        } else {
          this.buttonDisabled = false;
          const id = this.userid ? this.userid : "Could not get customer ID";
          const email = this.email ? this.email : "Could not get patient email";
          await this.$store.dispatch("sendErrorLog", {
            step: "Treatment Options",
            cus_id: id,
            email: email,
            error_log: err,
          });
          this.showAlert(err);
        }
      }
      this.buttonDisabled = false;
    },
    prev() {
      this.alert.show = false;
      switch (this.step) {
        case "productreveal":
          this.$emit("setOnboardingStep", 3.1);
          this.$router.push("/health-questions").catch(() => {});
          break;
        case "preferredshipment":
          // Tests if the user answered no
          this.step = this.agreedtomedication
            ? "productreveal"
            : "preferredtreatment";
          break;
        case "preferredtreatment":
          this.step = "productreveal";
          break;
      }
    },
    exit() {
      this.exiting = true;
      window.location.href = process.env.VUE_APP_WEBSITE_URL;
    },
    showAlert(message, type = null) {
      if (!type) type = "warning";
      this.alert = {
        show: true,
        type: "warning",
        message: message,
      };
      window.scrollTo(0, 0);
    },
  },
  watch: {
    step() {
      switch (this.step) {
        case "productreveal":
          this.$set(this.progress[2], "value", 1);
          break;
        case "preferredshipment":
          this.$set(this.progress[2], "value", 1.2);
          break;
        case "preferredtreatment":
          this.$set(this.progress[2], "value", 1.2);
          break;
      }
    },
  },
  async mounted() {
    try {
      window.analytics.page();
      const onboardingdata = await OnboardingService.getPatientOnboarding();

      this.onboardingData = onboardingdata.data;
      this.email = onboardingdata.data.email;
      this.firstname = onboardingdata.data.firstname;
      this.lastname = onboardingdata.data.lastname;
      this.phoneno = onboardingdata.data.phoneno;
      this.state = onboardingdata.data.state;

      this.onboardingflow = onboardingdata.data.onboardingflow;

      this.userid = onboardingdata.data.id;
      this.analyticsId = onboardingdata.data.usercustomid
        ? onboardingdata.data.usercustomid
        : onboardingdata.data.id;
      this.currentonboardingstep = onboardingdata.data.onboardingstep;

      // Redirect back if not done yet
      if (this.currentonboardingstep < 3) {
        this.exiting = true;
        window.location.href = "/";
      }

      // if (onboardingdata.data.selectedproduct) {
      //   for (let id of onboardingdata.data.selectedproduct) {
      //     // Get product
      //     const product = onboardingdata.data.desiredplan.find(
      //       (o) => o.productid == id
      //     );
      //     this.desiredplan.push({
      //       productid: id,
      //       pricingid: product.pricingdetail.pricingid,
      //     });
      //   }
      // } else {
      this.desiredplan = [];
      // }

      // this.desiredplan = onboardingdata.data.selectedproduct || [];

      const data = await OnboardingService.getProductReveal();
      this.revealedProducts = data.data;

      if (onboardingdata.data.agreedtomedication === undefined) {
        this.desiredplan = [];
        for (let record of this.revealedProducts) {
          this.desiredplan.push(record.id);
        }
      }

      const shipmentData = await LookUpService.getLookUp("preferredshipment");
      this.shippinginfo.answerlist = shipmentData.data;
      this.productRevealed = true;
      // this.$emit("showLoader", 5, "doctormatching");
    } catch (err) {
      if (err.status == 401) {
        this.exiting = true;
        this.$router.push("/login");
      } else {
        const id = this.userid ? this.userid : "Could not get customer ID";
        const email = this.email ? this.email : "Could not get patient email";
        await this.$store.dispatch("sendErrorLog", {
          step: "Treatment Options - Loading",
          cus_id: id,
          email: email,
          error_log: err,
        });
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    if (
      this.step == "productreveal" ||
      to.path == "/identity-verification" ||
      this.exiting
    ) {
      next();
    } else {
      this.prev();
      next(false);
    }
  },
};
</script>
