var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("OnboardingProgress", {
        attrs: { progress: _vm.progress },
        on: { back: _vm.prev },
      }),
      _c(
        "b-row",
        { staticClass: "onboarding-wrapper" },
        [
          _c("OnboardingHeader", {
            attrs: { step: "3", title: _vm.title },
            on: { back: _vm.prev },
          }),
          _c(
            "b-col",
            {
              staticClass: "mt-6 pt-3 onboarding-content",
              attrs: { cols: "12", lg: "5", xl: "5", xxl: "4" },
            },
            [
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
              _c(
                "b-row",
                { staticClass: "d-lg-none" },
                [
                  _c("b-col", [
                    _c(
                      "h3",
                      { staticClass: "bold d-letter-spacing onboarding-title" },
                      [_vm._v(" Onboarding Completion ")]
                    ),
                  ]),
                  _c("b-col", [
                    _c("h3", {
                      staticClass:
                        "bold d-letter-spacing onboarding-title text-muted text-right",
                    }),
                  ]),
                ],
                1
              ),
              _c("b-progress", {
                staticClass: "step-progress d-lg-none mb-5",
                attrs: {
                  value: 60,
                  max: 100,
                  variant: "purple",
                  height: "5px",
                },
              }),
              _vm.step == "productreveal"
                ? _c("ProductReveal", {
                    ref: "productReveal",
                    attrs: {
                      body: _vm.revealedProducts,
                      productRevealed: _vm.productRevealed,
                      selectedproduct: _vm.desiredplan,
                      "user-id": _vm.userid,
                      "analytics-id": _vm.analyticsId,
                      buttonDisabled: _vm.buttonDisabled,
                      email: _vm.email,
                      state: _vm.state,
                      phoneno: _vm.phoneno,
                      firstname: _vm.firstname,
                      lastname: _vm.lastname,
                      onboardingflow: _vm.onboardingflow,
                      "onboarding-data": _vm.onboardingData,
                    },
                    on: { submitAnswer: _vm.submitAnswer, exit: _vm.exit },
                  })
                : _vm._e(),
              _vm.step == "preferredshipment"
                ? _c("Single", {
                    staticClass: "shipping-price",
                    attrs: { body: _vm.shippinginfo },
                    on: { submitAnswer: _vm.submitAnswer },
                  })
                : _vm._e(),
              _vm.step == "preferredtreatment"
                ? _c("PreferredTreatment", {
                    ref: "PreferredTreatment",
                    attrs: { saving: _vm.treatmentformsaving },
                    on: {
                      submitAnswer: _vm.submitAnswer,
                      alert: _vm.showAlert,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "12", lg: "3", xl: "3", xxl: "4" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }