<template>
  <div class="preferred-treatment">
    <h2 class="question-title">What treatment would you prefer?</h2>
    <div role="group" class="group-mb">
      <v-select
        class="custom-input-style"
        :options="options"
        label="text"
        :reduce="(options) => options.value"
        v-model="selectedTreatment"
        append-to-body
        :searchable="false"
        :calculate-position="withPopper"
        placeholder="Select treatment type"
      >
        <template #open-indicator="{ attributes }">
          <b-icon v-bind="attributes" icon="chevron-down"></b-icon>
        </template>
      </v-select>
    </div>
    <b-row>
      <b-col cols="12" md="9" class="mb-3">
        <b-button
          variant="info"
          block
          pill
          @click="submitAnswer"
          :disabled="saving"
          >{{ saving ? "Saving" : "Continue" }}</b-button
        >
      </b-col>
      <b-col cols="12" md="9">
        <b-button
          v-if="!saving"
          variant="outline-info"
          block
          pill
          @click="submitDoctorChoice"
          :disabled="saving"
          >{{ saving ? "Saving" : "I want doctor to recommend" }}</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { LookUpService } from "@/services/lookup.service";
import popper from "@/assets/js/popper.js";

export default {
  mixins: [popper],
  props: {
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTreatment: "",
      otherOption: "",
      options: [],
      // optionData: []
    };
  },
  methods: {
    async getOptions() {
      let { data } = await LookUpService.getLookUp("treatmentform");

      data = data.filter((x) => x.othername2 != undefined);

      for (let item of data) {
        this.options.push({
          value: item.id,
          text: item.othername2,
        });
      }
      // this.optionData = this.options
      // this.options.push('Others');
      // this.optionData = data;
    },
    submitAnswer() {
      try {
        if (this.selectedTreatment == "" || this.selectedTreatment == null) {
          this.$emit("alert", "Please state your preferred treatment");
          return;
        }
        this.$emit(
          "submitAnswer",
          { doctorPref: false },
          this.selectedTreatment
        );
        // if(this.activateOthers) {
        //   if(this.otherOption == '') {
        //     this.$emit('alert', 'You must fill in all fields')
        //   } else {
        //     this.$emit('submitAnswer', {doctorPref: false}, this.otherOption);
        //   }
        // } else {
        //   let chosenItem = this.optionData.filter(item => item.name == this.selectedTreatment)[0];
        //   this.$emit('submitAnswer', {doctorPref: false}, chosenItem.id)
        // }
      } catch {
      } finally {
      }
    },
    submitDoctorChoice() {
      this.$emit("submitAnswer", { doctorPref: true });
    },
  },
  computed: {
    // activateOthers () {
    //   if(this.selectedTreatment == 'Others') {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
  },
  mounted() {
    this.getOptions();
  },
};
</script>

<style scoped>
/deep/.vs__dropdown-toggle:not(.custom-select) {
  padding-right: 0.75em;
}

/deep/.custom-input-style .vs__dropdown-toggle {
  margin-top: 10px;
  /* padding-left: 0.75em; */
  border: 0;
  background-color: rgba(242, 242, 242, 0.5);
  border-radius: 30px;
}

.vs__dropdown-toggle {
  height: 52px;
}

.preferred-treatment > .ash-bg {
  background: rgba(242, 242, 242, 0.5);
}
</style>

<style lang="scss" scoped>
.custom-control-label {
  line-height: 1.1;
}
</style>
